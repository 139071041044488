import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../contexts/authContext";
import { Link, navigate } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import LoadingStatus from "../../../components/LoadingStatus";
import ReportViewer from "../../../components/Privates/ReportViewer";
import useURLQueryParameter from "../../../hooks/queryParamHook";
import useNorthstar from "../../../hooks/northstarHook";
import PageLayout from "../../../components/PageLayout";
import PageContent from "../../../components/PageContent";
import PageBoundary from "../../../components/PageBoundary";

const Report = () => {
  const { accessToken, clearIdentity } = useAuthContext();
  const [id] = useURLQueryParameter("id");

  const [data, setData] = useState(null);

  const {
    data: reportData,
    isLoading,
    error,
  } = useNorthstar(`/api/transcript-privates/${id}`, accessToken, {});

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [JSON.stringify(reportData || "")]);

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id]);

  const renderReport = () => {
    if (error) return <LoadingStatus loadingType="report_details_error" />;
    if (isLoading)
      return <LoadingStatus loadingType="report_details_loading" />;
    if (!data) return <LoadingStatus loadingType="report_details_error" />;

    return <ReportViewer report={data} />;
  };

  let tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent>
        <PageBoundary>
          <nav
            className="flex mt-3 font-bebas-neue tracking-wide text-lg"
            aria-label="Breadcrumb"
          >
            <div className="flex items-center space-x-4">
              <Link
                to="/insights"
                className="text-bam-blue hover:text-bam-bright-blue"
              >
                All Reports
              </Link>
              <ChevronRightIcon
                className="h-3 w-3 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="text-bam-orange">{data?.company_name}</div>
            </div>
          </nav>
          {renderReport()}
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default Report;
